<template>
  <form @submit.prevent="login" class="mb-5">
    <sup dev @click.prevent="dev_prefill()">Prefill</sup>

    <div class="row mb-0">
      <div class="form-group col-md-6 col-sm-12">
        <label for="emailAddress">{{ $t("Emailová adresa") }}</label>
        <input
          v-model="credentials.identifier"
          type="email"
          class="form-control"
          :placeholder="$t('Zadajte email')"
          autocomplete="email"
        />

        <div class="support-section sign-in-prolem mt-4">
          <icon-help-circle-svg class="sign-in-prolem__icon mr-2" />
          <p class="mb-0">
            <span class="mr-1"
              >{{ $t("Problém s prihlásením?") }}
              <br class="sign-in-prolem__break" />{{ $t("Napíšte na") }}</span
            >
            <a href="mailto:skoly@zmudri.sk">skoly@zmudri.sk</a>
          </p>
        </div>
      </div>

      <div class="form-group col-md-6 col-sm-12">
        <label for="passwordConfirmation">{{ $t("Heslo") }}</label>
        <input
          v-model="credentials.password"
          type="password"
          class="form-control"
          :placeholder="$t('Zadajte heslo')"
          autocomplete="current-password"
        />

        <div
          class="support-section forgot-password mt-4"
          @click="navToForgotPassword"
        >
          <icon-lock class="forgot-icon" />
          {{ $t("Zabudol/a som heslo") }}
        </div>
      </div>
    </div>

    <div
      class="d-flex flex-wrap flex-column flex-lg-row align-items-stretch align-items-lg-center"
    >
      <b-button
        variant="primary"
        type="submit"
        :disabled="$toast.isLoading()"
        class="mr-0 mr-lg-2 mt-3"
      >
        {{ $t("Prihlásiť sa") }}
      </b-button>
      <b-button
        variant="secondary"
        @click.prevent="navToRegistration"
        class="mr-0 mt-3"
      >
        {{ $t("Registrácia - nové konto") }}
      </b-button>
    </div>
  </form>
</template>

<script>
import toastUtils from "/utils/toast/utils";
import * as Sentry from "@sentry/vue";
import { get } from "lodash";

export default {
  components: {
    "icon-lock": () => import("/assets/icons/lockClosed.svg?inline"),
    "icon-help-circle-svg": () => import("/assets/icons/helpCircle.svg?inline"),
  },
  props: {
    showHeader: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  computed: {
    userType() {
      const user = this.$store.getters["auth/user"];
      return user && user.profile && user.profile.type;
    },

    someCredentialsFilled() {
      return (
        get(this, "credentials.identifier") !== undefined &&
        get(this, "credentials.password") !== undefined &&
        this.credentials.identifier.length + this.credentials.password.length >
          0
      );
    },
  },

  data() {
    return {
      credentials: {
        identifier: "",
        password: "",
      },
    };
  },

  watch: {
    credentials: {
      deep: true,
      handler() {
        this.$emit("credentialsFilled", this.someCredentialsFilled);
      },
    },
  },

  async created() {
    window.addEventListener(
      "beforeunload",
      (event) => {
        if (this.someCredentialsFilled) {
          event.preventDefault();
          return (event.returnValue = "");
        }
      },
      { capture: true }
    );
  },

  methods: {
    openExternalLink(link) {
      window.open(link);
    },

    async login() {
      this.$toast.clear_loading();
      this.$eventLogger.userLog("user.login", { timestamp: new Date() });
      try {
        await this.$store.dispatch("auth/login", this.credentials);

        this.$toast.stopLoading();

        const lastRoute = localStorage.getItem("LAST_FROM_ROUTE");

        this.$emit("beforeLogin");
        if (lastRoute) {
          this.$router.push(lastRoute);
        } else {
          this.$router.push(
            this.userType === "student" ? "/oblubene-kurzy" : "/news"
          );
        }

        this.$emit("afterLogin");
        this.credentials = {};
      } catch (error) {
        if (error.response.status === 400) {
          this.$toast.error(this.$t("Incorrect credentials").toString());
        } else {
          Sentry.captureException(error);
          const str = toastUtils.parseErrorsToArrayOfStrings(error);
          this.$toast.error(str.join(". "));
        }
      }
    },

    navToRegistration() {
      if (this.$route.path != "/registracia") this.$router.push("/registracia");
      this.$emit("afterRegistration");
    },

    navToForgotPassword() {
      if (this.$route.path != "/prihlasit-sa/zabudnute-heslo")
        this.$router.push("/prihlasit-sa/zabudnute-heslo");
      this.$emit("afterForgotPassword");
    },

    dev_prefill() {
      this.credentials = {
        identifier: "testing@wezeo.com",
        password: "12345678",
      };
    },
  },
};
</script>
<style lang="scss" scoped>
form {
  font-family: "Poppins", sans-serif;
  white-space: normal;
}

.register-info {
  font-family: "Poppins";
  max-width: 467px;
  background: #fff5d1;
  border: 1px solid #e5d8a7;
  border-radius: 4px;
  margin: 20px auto;
  display: flex;
  align-items: center;

  img {
    margin-right: 30px;
  }

  p {
    color: #998c5c;
    white-space: normal;
    margin: 0;

    span.link {
      font-weight: 700;
      color: inherit;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  @media only screen and (max-width: 480px) {
    max-width: 20em;
  }
}

.support-section {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75rem;

  & * {
    font-size: 0.75rem;
  }
}

.forgot-password {
  cursor: pointer;

  .forgot-icon {
    width: 25px;
    height: 25px;
    margin: 0px 5px 7px 0px;
  }

  @media screen and (max-width: 384px) {
    margin-top: 10px;
  }
}

.sign-in-prolem {
  &,
  * {
    color: var(--a-color-secondary);
  }

  .sign-in-prolem__icon {
    width: 24px;
    min-width: 24px;
    height: 24px;
    min-height: 24px;
  }

  .sign-in-prolem__break {
    @media screen and (min-width: 576px) {
      display: none;
    }

    @media screen and (min-width: 768px) and (max-width: 1400px) {
      display: inline;
    }
  }

  a {
    font-weight: 700;
    text-decoration: underline;
  }
}
</style>
